import React, { useEffect, useState } from "react";
import Logo from "../img/Oya_logo.svg";
import type { MenuProps } from "antd";
import { Dropdown, Skeleton } from "antd";
import {
  ACCOUNTS_MS_API_URL,
  ACCOUNTS_URL,
  COOKIES_TOKEN_NAME,
} from "../helpers/constants";
import { Link } from "react-router-dom";
import { DASHBOARD_URL } from "../helpers/constants";
// import useAuthentication from '../helpers/auth';
import cookies from "js-cookie";
//import { useQuery } from '@tanstack/react-query';
// import api from "../helpers/api";
import axios from "axios";
import { COOKIES_USR } from "../helpers/constants";
import { useStore } from "../helpers/zustand";
import LogoutModal from "./LogoutModal";
import ContactUsModal from "./ContactUsModal";
import { PiLockKeyFill } from "react-icons/pi";

const Navbar = () => {
  //const authenticated = useAuthentication();
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [logoutModal, openLogoutModal] = useState(false);
  const [setUserName] = useStore((state) => [state.setUserName]);
  const [contactUs, openContactUs] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    ice1_phone: "",
    ice2_phone: "",
    phone: "",
    birthdate: "",
    gender: "",
    image: "",
    country: {
      code: "",
      name: "",
      phone_code: "",
    },
  });

  const items: MenuProps["items"] = [
    {
      type: "group",
      label: "Menu",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M0 11.1111H8.88889V0H0V11.1111ZM0 20H8.88889V13.3333H0V20ZM11.1111 20H20V8.88889H11.1111V20ZM11.1111 0V6.66667H20V0H11.1111Z"
            fill="#7C0000"
          />
        </svg>
      ),
      label: (
        <a
          className="pl-4 text-oya-ghana-header-h7 font-medium text-black"
          href={`${DASHBOARD_URL}`}
        >
          Dashboard
        </a>
      ),
      // onClick: () => window.location.replace(`${DASHBOARD_URL}`),
      key: "0",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            d="M0 0.993C0.00183004 0.730378 0.1069 0.479017 0.292513 0.293218C0.478126 0.107418 0.72938 0.00209465 0.992 0H19.008C19.556 0 20 0.445 20 0.993V17.007C19.9982 17.2696 19.8931 17.521 19.7075 17.7068C19.5219 17.8926 19.2706 17.9979 19.008 18H0.992C0.728813 17.9997 0.476497 17.895 0.290489 17.7088C0.104482 17.5226 -1.33455e-07 17.2702 0 17.007V0.993ZM4 12V14H16V12H4ZM4 4V10H10V4H4ZM12 4V6H16V4H12ZM12 8V10H16V8H12ZM6 6H8V8H6V6Z"
            fill="#7C0000"
          />
        </svg>
      ),
      label: (
        <a
          className="pl-4 text-oya-ghana-header-h7 font-medium text-black"
          href={`${ACCOUNTS_URL}`}
        >
          Account
        </a>
      ),
      key: "1",
    },
    {
      icon: <PiLockKeyFill color="#7C0000" size={23} />,
      label: (
        <a
          className="pl-[13px] text-oya-ghana-header-h7 font-medium text-black"
          href={`${ACCOUNTS_URL}/security-details`}
        >
          Security
        </a>
      ),
      key: "2",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
            clip-rule="evenodd"
            fill="#7C0000"
          />
        </svg>
      ),
      label: (
        <a
          className="pl-4 text-oya-ghana-header-h7 font-medium text-black"
          href={`${ACCOUNTS_URL}/verification`}
        >
          Verification
        </a>
      ),
      key: "3",
    },
    {
      type: "group",
      label: "Other",
    },
    {
      key: "4",
      label: (
        <button className="pl-[13px] text-oya-ghana-header-h7 font-medium text-black">
          Contact us
        </button>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="20"
          viewBox="0 0 25 20"
          fill="currentColor"
          className=" text-oya-ghana-green"
        >
          <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
          <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>
      ),
      onClick: () => {
        openContactUs(true);
      },
    },
    {
      type: "divider",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
        >
          <path
            d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z"
            fill="#7C0000"
          />
        </svg>
      ),
      onClick: () => {
        // handleLogout();
        openLogoutModal(true);
        // dispatch(logout());
      },
      label: (
        <button className="pl-4 text-oya-ghana-header-h7 font-medium text-black">
          Logout
        </button>
      ),
      key: "5",
    },
  ];

  const getDetails = async () => {
    if (userId) {
      setLoading(true);
      try {
        const user = await axios.get(`${ACCOUNTS_MS_API_URL}/v1/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        //console.log(user.data.data);
        setDetails(user.data?.payload);
        setUserName(user.data?.payload?.name);
        setLoading(false);
        //return user.data?.payload;
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-white border border-gray-100 w-full fixed header z-20 h-24 top-0 left-0">
      <LogoutModal
        isOpen={logoutModal}
        handleClose={() => {
          openLogoutModal(false);
        }}
      />
      <ContactUsModal
        isOpen={contactUs}
        handleClose={() => {
          openContactUs(false);
        }}
      />
      <div className="flex justify-between items-center max-[600px]:pl-4 pt-3 sm:mx-24 max-[600px]:mr-4">
        <Link to="/">
          <div className="flex flex-row items-center ">
            <img className="brand-logo" src={Logo} alt="brand logo" />
            <p className=" font-bold sm:text-3xl font-sans leading-5 tracking-wide pl-2">
              Oya!
            </p>
          </div>
        </Link>

        {userId && (
          <div className="flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl">
            <Dropdown
              trigger={["click"]}
              menu={{ items }}
              overlayStyle={{
                boxShadow: "#1d2a9f 0px 1px 4px",
                position: "relative",
                width: 256,
                borderRadius: 8,
              }}
              onOpenChange={() => setVisible(!visible)}
              placement="bottomRight"
              arrow
            >
              {loading ? (
                <div className="flex items-center user py-2">
                  <div className="h-12 w-12">
                    <Skeleton avatar />
                  </div>

                  <div className="text-left pl-2 pr-4 leading-snug hidden md:block">
                    <div className="text-oya-ghana-body-sb font-normal">
                      <div className="appear animate-pulse h-4 bg-black bg-opacity-[5%] rounded text-transparent w-fit">
                        Firstname Middlename Lastname
                      </div>
                      <div className="appear animate-pulse h-4 mt-2 bg-black bg-opacity-[5%] rounded text-transparent w-fit">
                        +2330000000000
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // <button onClick={(e) => e.preventDefault()}>
                //   <div className="flex text-left">
                //     {details?.image ? (
                //       <img
                //         className="rounded-full object-cover w-10 h-10 md:w-14 md:h-14"
                //         src={details?.image}
                //         alt="Profile"
                //       />
                //     ) : (
                //       <svg
                //         xmlns="http://www.w3.org/2000/svg"
                //         fill="none"
                //         viewBox="0 0 24 24"
                //         strokeWidth={1.5}
                //         stroke="currentColor"
                //         className="w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green"
                //       >
                //         <path
                //           strokeLinecap="round"
                //           strokeLinejoin="round"
                //           d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                //         />
                //       </svg>
                //     )}

                //     <div className="text-left pl-2 pr-4 leading-snug hidden md:block">
                //       <h2 className="text-oya-ghana-body-normal font-medium">
                //         {details?.name}
                //       </h2>
                //       <div className="text-oya-ghana-body-sb font-normal">
                //         {details?.phone}
                //       </div>
                //     </div>
                //     <svg
                //       xmlns="http://www.w3.org/2000/svg"
                //       width="18"
                //       height="10"
                //       viewBox="0 0 18 10"
                //       stroke="currentColor"
                //       className="w-4 h-4 text-oya-ghana-green md:mb-4"
                //     >
                //       <path
                //         strokeLinecap="round"
                //         strokeLinejoin="round"
                //         d="M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z"
                //       />
                //     </svg>

                //   </div>
                // </button>
                <button
                  type="button"
                  className="flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl"
                >
                  <div className="flex items-center user">
                    {details?.image ? (
                      <img
                        src={details?.image}
                        className="appear rounded-full object-cover w-12 h-12 md:w-14 md:h-14"
                        alt="User"
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}

                    <div className="text-left pl-2 pr-4 leading-snug hidden md:block">
                      <h2 className="text-oya-ghana-body-normal font-medium">
                        {details?.name}
                      </h2>
                      <div className="text-oya-ghana-body-sb font-normal">
                        {details?.phone}
                      </div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="10"
                      viewBox="0 0 18 10"
                      stroke="currentColor"
                      className="w-4 h-4 text-oya-ghana-green md:mb-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z"
                      />
                    </svg>
                  </div>
                </button>
              )}
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
