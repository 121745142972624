// api.js
import axios from "axios";
import {
  ACCOUNTS_MS_API_URL,
  API_URL,
  COOKIES_TOKEN_NAME,
  RIDE_MS_URL,
} from "./constants";
// import { handleLogout } from "./utils";
import Cookies from "js-cookie";

const authToken = Cookies.get(`${COOKIES_TOKEN_NAME}`);

const api = axios.create({
  baseURL: `${API_URL}`,

  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const accounts_ms = axios.create({
  baseURL: ACCOUNTS_MS_API_URL,
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const ride_ms = axios.create({
  baseURL: RIDE_MS_URL,
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

// api.interceptors.response.use(
//   (response) => {
//     // if (response.data.status !== 200) {
//     //   handleLogout();
//     // }
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       handleLogout();
//       console.log(error.response);
//     }
//     return Promise.reject(error);
//   }
// );

export default api;
