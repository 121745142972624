export const API_URL = process.env.REACT_APP_API_URL;
export const ACCOUNTS_MS_API_URL = process.env.REACT_APP_ACCOUNTS_MS_API_URL;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const RIDE_MS_URL = process.env.REACT_APP_RIDE_MS_URL;
// export const API_ROOT = process.env.REACT_APP_LARAVEL_API_ROOT;
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const DEFAULT_REF = process.env.REACT_APP_DEFAULT_REF;
export const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;
export const VERSION = require("../../package.json").version;
export const DB_PREF = "oya_";
export const COOKIES_SECURED = process.env.REACT_APP_COOKIES_SECURED;
export const USER_DATA = {
  img: "https://www.pngitem.com/pimgs/m/105-1055689_user-account-person-avatar-operating-system-grey-user.png",
  first_name: "George",
  middle_name: "",
  last_name: "Arthur",
  dob: "1976/03/12",
  mobile_number: "+233246789423",
  country: "Ghana",
  ice1_phone: "+233246789423",
  ice2_phone: "+233246789423",
  state: "Accra, Ghana",
  zip: "0223",
  country_membership_id: "GHA-67348994-0",
  email: "g.man@gmail.com",
};

export const COOKIES_TOKEN_NAME = "oya_token";
export const COOKIES_USER_NAME = "oya_user";
export const COOKIES_USR = "oya_usr";
export const COOKIES_USER_INFO = "oya_user_info";
export const COOKIES_ROLE = "oya_selected_user_role";
export const COOKIES_SERVICE = "oya_selected_service";

export const RouteConstants = {
  REQUEST_VERIFICATION: "/v2/auth/send-verification",
  VERIFY_PHONE: "/v2/auth/verify",
  REGISTER: "/v2/auth/register",
  PROFILE: "/v1/profile",
  PHOTO_SIGNED_UPLOAD: "/v1/profile/image/presigned-upload",
  NETWORK_PROVIDERS: "/v2/providers",
};

export const url =
  "https://www.pngitem.com/pimgs/m/105-1055689_user-account-person-avatar-operating-system-grey-user.png";
