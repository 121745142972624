import { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
// import api from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import MainLayout from "../../components/MainLayout";
import cookies from "js-cookie";
import { useStore } from "../../helpers/zustand";
import { COOKIES_USR, COOKIES_TOKEN_NAME } from "../../helpers/constants";
//import { handleLogout } from "../../helpers/utils";
import { notification, Avatar } from "antd";
import { accounts_ms } from "../../helpers/api";

const Main = () => {
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  // const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    ice1_phone: "",
    ice2_phone: "",
    phone: "",
    birthdate: "",
    gender: "",
    email: "",
    image: "",
    country: {
      code: "",
      name: "",
      phone_code: "",
    },
    payment_account_number: "",
    payment_account_provider: "",
  });
  const [setImgUrl, setUserName] = useStore((state: any) => [
    state.setImgUrl,
    state.setUserName,
  ]);
  const navigate = useNavigate();

  const { isLoading, error } = useQuery({
    retry: 0,
    queryKey: ["user-details-main"],
    queryFn: async () => {
      if (token && userId) {
        try {
          const user = await accounts_ms.get(RouteConstants.PROFILE);
          // console.log(user.data);
          setDetails(user.data?.payload);
          setImgUrl(user.data?.payload?.image);
          setUserName(user.data?.payload?.name);
          return user.data?.payload;
        } catch (e: any) {
          notification.error({
            message: e?.message,
          });
          console.log(e);
        }
      }
    },
  });
  if (error) {
    console.log(error);
    //notification.error({ message: "Error fetching user details" });
  }

  if (token && userId) {
    return (
      <>
        <MainLayout title="Account" subTitle="Your personal details">
          <div className="flex flex-col items-start flex-wrap sm:mt-[200px] mb-14 sm:mb-8">
            {isLoading ? (
              <div className="flex gap-4 items-center">
                <Skeleton circle={true} width={100} height={100} />
                <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
              </div>
            ) : (
              <div className="flex items-center gap-2 sm:w-2/3 transition-all duration-1000 ease-out">
                {details?.image ? (
                  <Avatar
                    src={details?.image}
                    alt="Profile"
                    size={{
                      xl: 160,
                      xs: 100,
                      md: 160,
                      xxl: 160,
                      lg: 160,
                    }}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className=" w-24 h-24 sm:w-32 sm:h-32 rounded-full text-oya-ghana-green"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                )}
                <div className="capitalize sm:text-oya-ghana-header-h5 text-oya-ghana-header-h6">
                  Profile info
                </div>
                <button
                  onClick={() => navigate(`/account/edit-profile/${userId}`)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 sm:w-7 sm:h-7 text-oya-ghana-green"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </button>
              </div>
            )}

            {/* details */}
            <div className="mt-4 border-[1px] border-gray-300 rounded-[10px] shadow-sm bg-white p-5 w-full">
              <p className="font-normal text-2xl">Basic Info</p>
              <hr className="my-3" />

              <div
                className="w-full text-gray-700 flex gap-[50px] items-center"
                data-testid="first-name-profile"
              >
                <p className="text-md w-[200px]">First Name:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details?.first_name}</p>
                )}
              </div>
              <hr className="my-3" />

              <div
                className="w-full text-gray-700 flex gap-[50px] items-center"
                data-testid="middle-name-profile"
              >
                <p className="text-md w-[200px]">Middle Name:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">
                    {!details?.middle_name ? "N/A" : details?.middle_name}
                  </p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Last Name:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details?.last_name}</p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Date Of Birth:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">
                    {!details?.birthdate ? "N/A" : details?.birthdate}
                  </p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Country:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details?.country?.name}</p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Gender:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">
                    {!details?.gender
                      ? "N/A"
                      : details?.gender === "M"
                      ? "Male"
                      : details?.gender === "F"
                      ? "Female"
                      : "Other"}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-6 border-[1px] border-gray-300 rounded-[10px] shadow-sm bg-white p-5 w-full">
              <p className="font-normal text-2xl">Contact Info</p>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Phone Number:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details?.phone}</p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Email:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">
                    {details?.email ? details?.email : "N/A"}
                  </p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Emergency Contact 1:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details.ice1_phone}</p>
                )}
              </div>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Emergency Contact 2:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details.ice2_phone}</p>
                )}
              </div>
              <hr className="my-3" />
            </div>

            <div className="mt-6 border-[1px] border-gray-300 rounded-[10px] shadow-sm bg-white p-5 w-full">
              <p className="font-normal text-2xl">Payment Info</p>
              <hr className="my-3" />

              <div className="w-full text-gray-700 flex gap-[50px] items-center">
                <p className="text-md w-[200px]">Provider Phone Number:</p>
                {isLoading ? (
                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                ) : (
                  <p className="text-xl">{details?.payment_account_number}</p>
                )}
              </div>
              <hr className="my-3" />
            </div>
          </div>
        </MainLayout>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default Main;
